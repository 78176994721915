<template>
  <div v-if="schema" :style="{ fontSize: fontSize + 'px' }" class="json-schema-input">
    <div v-for="(id, index) in sort" :key="index">
      <template v-if="schema.properties[id]">
        <template v-if="schema.properties[id].enum.length > 0">
          <template v-if="schema.properties[id].widget === CODE.MULTI_CHOICE">
            <b-form-group
              :disabled="schema.isLocked"
              :label="
                (i18n.locale === 'ko' ? schema.properties[id].name : schema.properties[id].code) +
                ' ' +
                (schema.required.includes(id) ? '*' : '')
              ">
              <b-form-checkbox-group
                v-if="Array.isArray(form[schema.properties[id].code])"
                v-model="form[schema.properties[id].code]">
                <b-form-checkbox
                  v-for="(d, i) in schema.properties[id].enum"
                  :key="d + i"
                  :value="d"
                  class="checkbox mr-3"
                  :data-code="schema.properties[id].code">
                  <span v-if="i18n.locale === 'ko'" :style="{ fontSize: fontSize + 'px' }">
                    {{ schema.properties[id].enumNames[i] }}
                  </span>
                  <span v-else :style="{ fontSize: fontSize + 'px' }">{{ schema.properties[id].enum[i] }}</span>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </template>
          <template v-else-if="schema.properties[id].widget === 'single_choice--radio'">
            <b-form-group :disabled="schema.isLocked">
              <template #label>
                <div @dblclick="selectNextOption(schema.properties[id].code, getOptions(schema.properties[id]))">
                  {{
                    (i18n.locale === 'ko' ? schema.properties[id].name : schema.properties[id].code) +
                    ' ' +
                    (schema.required.includes(id) ? '*' : '')
                  }}
                </div>
              </template>
              <b-form-radio-group v-model="form[schema.properties[id].code]" class="single_choice--radio" stacked>
                <b-form-radio
                  v-for="(name, i) in schema.properties[id].enum"
                  :key="name"
                  :class="{ 'radio-item--active': schema.properties[id].enum[i] === form[schema.properties[id].code] }"
                  :value="schema.properties[id].enum[i]"
                  class="w-100"
                  size="sm"
                  :data-code="schema.properties[id].code">
                  <div>
                    <span v-if="i18n.locale === 'ko'">{{ schema.properties[id].enumNames[i] }}</span>
                    <span
                      v-if="schema.properties[id].enumValues"
                      :style="{ color: schema.properties[id].enumValues[i] }">
                      ●
                    </span>
                  </div>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </template>
          <template v-else>
            <b-form-group :disabled="schema.isLocked">
              <template #label>
                <div @dblclick="selectNextOption(schema.properties[id].code, getOptions(schema.properties[id], true))">
                  {{
                    (i18n.locale === 'ko' ? schema.properties[id].name : schema.properties[id].code) +
                    ' ' +
                    (schema.required.includes(id) ? '*' : '')
                  }}
                </div>
              </template>
              <b-form-select
                v-model="form[schema.properties[id].code]"
                :options="getOptions(schema.properties[id], true)"
                :style="{ fontSize: fontSize + 'px' }"
                size="sm"
                :data-code="schema.properties[id].code">
                <template #first>
                  <b-form-select-option :value="''">{{ `-- ${t('dashboard.term.select')} --` }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </template>
        </template>
        <template v-else>
          <template v-if="schema.properties[id].widget === 'text'">
            <b-form-group :label="`${getTextareaLabel(id)} (${getTextLength(id)})`" :disabled="schema.isLocked">
              <textarea
                ref="textInput"
                v-model="form[schema.properties[id].code]"
                class="textarea"
                :style="{ fontSize: fontSize + 'px' }"
                max-rows="100"
                no-auto-shrink
                rows="3"
                :data-code="schema.properties[id].code"
                :data-id="id"
                @input="onTextareaInput"
                @blur="onTextareaBlur"></textarea>
            </b-form-group>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted, onUnmounted } from '@vue/composition-api'
import { cloneDeep, isEqual, debounce } from 'lodash'
import { useStore } from '@/app/app-store'
import { cleanClassification } from '@/app/core/lib/data'
import { useI18n } from '@/app/core/lib/util'
import i18n from '@/i18n'

let prevCodes = []

export default {
  name: 'JsonSchemaInput',
  props: { context: Object },
  setup(props) {
    let watchers = []
    const { $store } = useStore()
    const CODE = {
      CLASS_SELECT: 'class-select',
      SINGLE_CHOICE: 'single_choice',
      MULTI_CHOICE: 'multi_choice',
      CLASSIFICATION: 'classification'
    }
    const fontSize = computed(() => $store.state.annotator.preferences.annotation.fontSize)
    const schema = computed(() => props.context.slotProps.label.schema)
    const annotationType = computed(() => props.context.slotProps.label.annotationType)
    const usedBy = computed(() => props.context.slotProps.label.usedBy || CODE.CLASSIFICATION)
    const form = ref({ class: '' })
    const originForm = ref({})
    const sort = ref([])
    const { t } = useI18n()
    const textInput = ref(null)
    const hasSelectedClassificationOfAnnotationTypes = {}
    const currentCharacterCount = ref(0)
    const currentActiveTextarea = ref(null)

    function findCode(obj, keys, i) {
      if (!keys[i]) return findCode(obj, keys, i + 1)
      if (keys.length - 1 === i) return obj[keys[i]].code
      return findCode(obj[keys[i]], keys, i + 1)
    }

    function initClassSelectionProps(property) {
      if (!form.value[property.code]) {
        setDefaultValue(property)
      }

      if (!annotationType.value) return false

      const annotationTypeCode = annotationType.value
      const propertyCode = property.code

      if (hasSelectedClassificationOfAnnotationTypes?.[annotationTypeCode]?.[propertyCode] === true) {
        return false
      }

      if (hasSelectedClassificationOfAnnotationTypes[annotationTypeCode]) {
        hasSelectedClassificationOfAnnotationTypes[annotationTypeCode][propertyCode] = true
      } else {
        hasSelectedClassificationOfAnnotationTypes[annotationTypeCode] = { [propertyCode]: true }
      }
    }

    function setDefaultValue(property) {
      if (usedBy.value === CODE.CLASS_SELECT) {
        if (originForm.value[property.code]) {
          form.value[property.code] = property.widget === CODE.MULTI_CHOICE ? [] : ''
        } else {
          form.value[property.code] = property.default
        }
      } else {
        let defaultValue = getDefaultValue(property)
        if (defaultValue !== null) {
          form.value[property.code] = defaultValue
        }
      }
    }

    function getDefaultValue(property) {
      if (property.default !== '') {
        return property.widget === CODE.MULTI_CHOICE ? [] : ''
      } else if (property.default === '') {
        return property.default
      }
      return null
    }

    function isVisible(property) {
      let result = false
      if (property) {
        if (property.condition) {
          const ref = property.condition.$ref
          const value = property.condition.const
          const code = findCode(schema.value, ref.split('/'), 0)
          result = form.value[code] === value
        } else {
          result = true
        }
      }
      return result
    }

    function setSort() {
      if (schema.value) {
        const { properties } = schema.value
        sort.value = []
        for (const id of schema.value.sort) {
          const property = properties[id]
          if (isVisible(property)) {
            initClassSelectionProps(property)
            sort.value.push(id)
          }
        }
      }
    }

    function onTextareaInput({ target }) {
      if (target) {
        const length = target.value?.length
        currentActiveTextarea.value = target
        currentCharacterCount.value = length
      }
    }

    function onTextareaBlur() {
      currentCharacterCount.value = 0
    }

    function getOptions(property) {
      const options = []
      for (let i = 0; i < property.enum.length; i++) {
        const d = property.enum[i]
        let text
        if (i18n.locale === 'ko') {
          text = property.enumNames[i]
        } else {
          text = d
        }
        options.push({
          text,
          value: d
        })
      }
      return options
    }

    function selectNextOption(code, options) {
      const model = form.value[code]
      if (options && options.length > 1) {
        let idx = options.findIndex((d) => d.value === model)

        if (idx === options.length - 1) {
          idx = 1
        } else {
          idx++
        }
        form.value[code] = options[idx].value
      }
    }

    function onTextInput({ target }) {
      if (target) {
        target.style.height = 'auto'
        target.style.height = target.scrollHeight + 'px'
      }
    }

    function applyNewTextInputHeight() {
      const v = textInput.value
      if (v?.length > 0) {
        v.forEach((d) => {
          d.setAttribute('style', 'height:' + d.scrollHeight + 'px;overflow-y:hidden;')
          d.addEventListener('input', onTextInput, false)
        })
      }
    }

    function getTextareaLabel(id) {
      return `${schema.value.properties[id].name} ${schema.value.required.includes(id) ? '*' : ''}`
    }

    function getTextLength(id) {
      const activeElement = currentActiveTextarea.value
      const activeElementId = activeElement?.getAttribute('data-id')
      const isFocused = id === activeElementId

      if (isFocused) {
        currentCharacterCount.value = activeElement?.value?.length || 0
        return currentCharacterCount.value
      } else {
        return form.value?.[schema.value.properties[id].code]?.length || 0
      }
    }

    function getPropsString(newValue) {
      const result = new Set()
      const keys = []
      newValue.sort.map((key) => {
        processProperty(newValue, key, result, keys)
      })

      const codes = keys.map((key) => newValue.properties[key].code)

      if (!isEqual(prevCodes, codes)) {
        cleanFormValues(codes)
        prevCodes = cloneDeep(codes)
      }
      return Array.from(result).join()
    }

    function processProperty(newValue, key, result, keys) {
      const property = newValue.properties[key]
      if (property.condition) {
        handleConditionProperty(newValue, property, result, keys, key)
      } else {
        result.add(property.code)
        keys.push(key)
      }
    }

    function handleConditionProperty(newValue, property, result, keys, key) {
      const propertyId = property.condition.$ref.substr(property.condition.$ref.lastIndexOf('/') + 1)
      const value = property.condition.const
      if (props.context.model[newValue.properties[propertyId].code] === value) {
        result.add(property.code)
        const propertyCode = props.context.model[newValue.properties[key].code]
        if (propertyCode && typeof propertyCode === 'object') {
          handleObjectProperty(newValue, propertyCode, keys, key)
        } else if (typeof propertyCode === 'string') {
          handleStringProperty(newValue, propertyCode, keys, key)
        }
      } else {
        result.add(property.code)
        keys.push(key)
      }
    }

    function handleObjectProperty(newValue, propertyCode, keys, key) {
      for (const value of propertyCode) {
        if (newValue.properties[key].enum.includes(value)) {
          keys.push(key)
          break
        } else {
          break
        }
      }
    }

    function handleStringProperty(newValue, propertyCode, keys, key) {
      if (newValue.properties[key].widget === 'text' || newValue.properties[key].enum.includes(propertyCode)) {
        keys.push(key)
      }
    }

    function cleanFormValues(codes) {
      Object.keys(form.value).map((d) => {
        if (!codes.includes(d)) {
          form.value[d] = ''
        }
      })
    }

    onMounted(() => {
      watchers = []
      watchers.push(
        watch(
          form,
          (newValue) => {
            let _v = newValue
            if (newValue?.class === undefined) {
              _v = { class: '' }
            } else if (newValue.class === '') {
              _v = cleanClassification(form.value)
            }
            // eslint-disable-next-line vue/no-mutating-props
            props.context.model = _v || { class: '' }
            setSort()
          },
          { deep: true, flush: 'post' }
        ),
        watch(
          schema,
          debounce((newValue) => {
            const stringfiedProps = getPropsString(newValue)
            const newData = { ...props.context.model }
            const propertyKeys = stringfiedProps.split(',')
            Object.keys(newData).forEach((key) => {
              if (!propertyKeys.includes(key)) {
                delete newData[key]
              }
            })
            form.value = newData
            originForm.value = { ...newData }
          }, 1),
          { flush: 'post' }
        ),
        watch(
          textInput,
          () => {
            applyNewTextInputHeight()
          },
          { once: true }
        )
      )
    })

    onUnmounted(() => {
      watchers.forEach((unwatch) => unwatch())
    })

    return {
      CODE,
      onTextareaInput,
      onTextareaBlur,
      t,
      schema,
      form,
      sort,
      i18n,
      isVisible,
      getOptions,
      fontSize,
      selectNextOption,
      textInput,
      getTextareaLabel,
      getTextLength
    }
  }
}
</script>

<style lang="scss" scoped>
input,
textarea,
select {
  color: #000 !important;
}

.checkbox {
  display: inline-block;
}

.radio-item--active {
  background: var(--border-color);
}

.textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  color: var(--font-color);
  max-height: 800px;
  overflow-x: hidden;
  overflow-y: auto !important;
}

.tag {
  text-align: center;
  min-width: 50px;
  font-size: 0.7em;
  font-weight: bold;
  font-family: monospace;
  margin: 0 0 5px 0;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #6f84a3;
  display: inline-block;
}
</style>

<style lang="scss">
.json-schema-input {
  .custom-control-input {
    appearance: none !important;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    cursor: pointer;
  }
}
</style>
