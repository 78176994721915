import { render, staticRenderFns } from "./tag-input.vue?vue&type=template&id=280b1384&scoped=true"
import script from "./tag-input.vue?vue&type=script&lang=js"
export * from "./tag-input.vue?vue&type=script&lang=js"
import style0 from "./tag-input.vue?vue&type=style&index=0&id=280b1384&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280b1384",
  null
  
)

export default component.exports