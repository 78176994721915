var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("formulate-input-element formulate-input-element--" + (_vm.context.class)),attrs:{"data-type":_vm.context.type}},[_c('div',[_c('b-form-tags',{attrs:{"placeholder":_vm.$t('dashboard.term.addTag') + '...',"separator":";"},on:{"tag-state":_vm.onTagState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('div',[_c('ul',{staticClass:"b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center"},[_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"b-form-tag"},[_c('span',{class:[
                  _vm.errorTag.includes(tag) ? 'badge-danger' : 'badge-secondary',
                  'badge b-form-tag d-inline-flex mw-100'
                ]},[_c('input',{class:[
                    _vm.errorTag.includes(tag) ? 'badge-danger' : 'badge-secondary',
                    'm-0 b-form-tag-content text-truncate b-form-tag tag-edit-input'
                  ],attrs:{"type":"text"},domProps:{"value":tag},on:{"change":_vm.changeTag,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.changeTag.apply(null, arguments)}}}),_vm._v(" "),_c('button',{staticClass:"close b-form-tag-remove",attrs:{"aria-label":"Remove tag","type":"button"},on:{"click":function($event){$event.stopPropagation();return removeTag(tag)}}},[_vm._v("\n                  ×\n                ")])])])}),_vm._v(" "),_c('li',{staticClass:"b-from-tags-field flex-grow-1"},[_c('div',{staticClass:"d-flex",attrs:{"role":"group"}},[_c('input',_vm._g(_vm._b({staticClass:"shadow-none b-form-tags-input w-100 flex-grow-1 p-0 m-0 bg-transparent border-0",attrs:{"type":"text","value":""}},'input',inputAttrs,false),inputHandlers)),_vm._v(" "),(_vm.validTag)?_c('button',{staticClass:"btn b-form-tags-button py-0 btn-outline-secondary",staticStyle:{"font-size":"90%"},attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return addTag()}}},[_vm._v("\n                  Add\n                ")]):_vm._e()])])],2),_vm._v(" "),(tags.length > 0 && _vm.duplicatedTag)?_c('div',{attrs:{"role":"group"}},[_c('small',{staticClass:"form-text text-muted"},[_vm._v("\n              "+_vm._s(((_vm.$t('dashboard.term.duplicateTags')) + ": " + _vm.duplicatedTag))+"\n            ")])]):_vm._e(),_vm._v(" "),(tags.length > 0)?_c('button',{staticClass:"btn b-form-tags-button btn-outline-dark remove-all-tags-btn py-0",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.removeAllTags()}}},[_vm._v("\n            "+_vm._s(_vm.$t('dashboard.term.removeAll'))+"\n          ")]):_vm._e()])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }